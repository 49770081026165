import styled, { keyframes } from "styled-components";
import {
  FacebookWithCircle,
  LinkedinWithCircle,
  InstagramWithCircle,
} from "styled-icons/entypo-social";
import media from "styled-media-query";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

export const InstagramIcon = styled(InstagramWithCircle)``;
export const LinkedinIcon = styled(LinkedinWithCircle)``;
export const FacebookIcon = styled(FacebookWithCircle)``;

const Slide = keyframes`
  from {  transform: scale(1.2)} 
  to {   transform: scale(1)}
`;

export const Container = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
`;

export const CardImage = styled(Img)``;

export const BgImage = styled(BackgroundImage)`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;

  overflow: hidden;

  &::before,
  &::after {
    background-position: 50% 50% !important;
    animation-name: ${Slide};
    animation-duration: 5s;
    filter: opacity(0.3);
  }
`;
export const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  text-shadow: 3px 3px 2px rgba(255, 255, 255, 1);
  position: relative;
  padding-bottom: 1rem;
  max-width: 80%;
  margin: 0 0 3rem 0;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background: ${({ theme }) => theme.colors.titlePage};
    z-index: 0;
    box-shadow: 0 1px 1px rgba(115, 41, 55, 1);
  }

  ${media.greaterThan("large")`   
  font-size: 6rem;
  `}
`;

export const Content = styled.div`
  width: 95vw;
  height: 100%;
  margin: 5rem auto 0;
  padding: 2.5rem 1.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  button {
    border: none;
  }

  p {
    font-size: 2rem;
    line-height: 3.2rem;
    margin-bottom: 3rem;
    font-weight: 400;
    font-family: "Lora", serif;
    text-align: center;
    width: 90vw;
  }
  h2 {
    margin: 3.2rem 0 1.6rem;
    font-size: 2.2rem;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.primary};
  }
  &::before,
  &::after {
    border-radius: 0.5rem;
  }
  ${CardImage} {
    width: 90vw;
    height: auto;
    padding: 2rem;
    border-radius: 0.4rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    p {
      width: 65rem;
      font-size: 2.5rem;
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    max-width: 800px;
  }
  @media (min-width: 1025px) {
    max-width: 960px;
    ${CardImage} {
      width: 70rem;
      height: auto;
    }
  }
`;
export const Social = styled.div`
  display: block;
  padding: 0 1.6rem;
  width: 90vw;
  margin: 0 auto;
  span {
    display: block;
    font-size: 2.5rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.6rem 0;
    a {
      cursor: pointer;
    }

    ${InstagramIcon}, ${LinkedinIcon}, ${FacebookIcon} {
      width: 4rem;
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;
    }
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }
`;

export const VideoModal = styled.div`
  $animation-speed: 0.3s;
  $animation-function: ease-out;
  $backdrop-color: rgba(0, 0, 0, 0.5);

  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: $backdrop-color;
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: $animation-function;
    animation-duration: $animation-speed;
    animation-name: modal-video;
    -webkit-transition: opacity $animation-speed $animation-function;
    -moz-transition: opacity $animation-speed $animation-function;
    -ms-transition: opacity $animation-speed $animation-function;
    -o-transition: opacity $animation-speed $animation-function;
    transition: opacity $animation-speed $animation-function;
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 720px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }

  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.8);
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: $animation-function;
    animation-duration: $animation-speed;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform $animation-speed $animation-function;
    -moz-transition: -moz-transform $animation-speed $animation-function;
    -ms-transition: -ms-transform $animation-speed $animation-function;
    -o-transition: -o-transform $animation-speed $animation-function;
    transition: transform $animation-speed $animation-function;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 3px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;
