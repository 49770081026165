import React from "react";
/* eslint-disable max-len */

import SEO from "../components/Seo";
import ThankYou from "../components/template-parts/ThankYou";

function ThankYouPage() {
  return (
    <>
      <SEO title="Muito Obrigada" />
      <ThankYou />
    </>
  );
}

export default ThankYouPage;
