import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import { useStaticQuery, graphql, Link } from "gatsby";

import {
  Container,
  Title,
  BgImage,
  CardImage,
  Content,
  Social,
  InstagramIcon,
  LinkedinIcon,
  FacebookIcon,
  VideoModal,
} from "./styles";

function ThankYou() {
  const [isOpen, setOpen] = useState(false);
  const { banner, card } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "bgImage/bg-obrigado.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      card: file(relativePath: { eq: "curso/card-video-obrigada.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <Container>
        <VideoModal>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="UFI_KAFTCWo?controls=0"
            onClose={() => setOpen(false)}
          />
        </VideoModal>
        <BgImage fluid={banner.childImageSharp.fluid}>
          <Content>
            <Title>Obrigada!</Title>

            <p>
              Agora falta pouco, assista ao vídeo de boas vindas, nele eu já te explico como será o
              seu acesso ao curso.
            </p>
            <button onClick={() => setOpen(true)}>
              <CardImage fluid={card.childImageSharp.fluid} onClick={() => setOpen(true)} />
            </button>
          </Content>
          <Social>
            <span>Siga-me</span>
            <div className="icons">
              <Link to="https://www.instagram.com/helenacampiglia/" target="_blank" alt="Instagram">
                <InstagramIcon />
              </Link>
              <Link
                to="https://www.linkedin.com/in/helena-campiglia-60a743201/"
                target="_blank"
                alt="Linkedin">
                <LinkedinIcon />
              </Link>
              <Link
                to="https://www.facebook.com/drahelenacampiglia/"
                target="_blank"
                alt="Facebook">
                <FacebookIcon />
              </Link>
            </div>
          </Social>
        </BgImage>
      </Container>
    </>
  );
}

export default ThankYou;
